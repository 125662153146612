@use "./variables" as variable;
@use "./mixin" as mixin;
@import "./animations";
@import "./base";

.form_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;

  /* @include mixin.tablet {
    background-color: variable.$black-color;
  } */
}
.form_wrapper input[type="submit"],
.form_wrapper button[type="submit"] {
  padding: 10px 20px;
  color: variable.$black-light-color;
  background-color: variable.$gold-color;
  margin: 20px 0;
  cursor: pointer;
  min-width: 125px;
}
.form_wrapper button.btn_submit {
  background-color: variable.$gold-color;
  color: variable.$black-light-color;
  border-radius: 5px;
  padding: 12px 20px;
  border: 0;
  font-size: 15px;
  line-height: 24px;
}
.tooltip_field4 {
  margin-bottom: 30px !important;
  margin-left: 15px;
}

.w_100 {
  width: 100%;
}
.text_center {
  text-align: center;
}
.flex_row_reverse {
  flex-direction: row-reverse;
}

.flex_column {
  flex-direction: column;
}
.d_flex {
  display: flex;
}
.neom_content {
  display: flex;
  width: 100%;
}
.login_page {
  background-image: linear-gradient(
      180deg,
      rgba(19, 16, 13, 0.9) 0%,
      rgba(19, 16, 13, 0) 100%
    ),
    url("../images/about-c26-hero\ 1.jpg");
  background-size: cover;
  background-position: center center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 15px;
}
.devider_vertical {
  width: 1.3px;
  height: 23px;
  background-color: variable.$black-color;
}
.login_title_head {
  color: variable.$black-darker-color;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
}
.login_title {
  font-size: 32px;
  line-height: 48px;
  color: variable.$black-light-color;
  text-align: center;
  letter-spacing: -0.03em;
}
.login_desc {
  font-size: 14px;
  line-height: 24px;
  color: variable.$black-light-color;
  text-align: center;
  letter-spacing: -0.01em;
}
.login_wrapper {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
}
.error_title {
  font-size: 30px;
  line-height: 44px;
  color: #1d1f22;
}
.error_title2 {
  padding-right: 10px;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  color: #ebc03f;
}
.error_desc {
  font-size: 15px !important;
}
.box_shadow {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
}
.w-100 {
  width: 100% !important;
}
.error_text {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: variable.$red-dark-color;
}
.showPassword {
  position: absolute;
  top: 13.5px;
  right: 20px;
  cursor: pointer;
}

.iconic_input {
  position: absolute;
  top: 14.5px;
  right: 20px;
  pointer-events: none;
}
.iconic_input_small,
.iconic_input_small2 {
  position: absolute;
  top: 14.5px;
  right: 0px;
  pointer-events: none;
}
.iconic_left_input {
  position: absolute;
  top: 12px;
  left: 7px;
  pointer-events: none;
}
.main_title,
.loading_text,
.title_md,
.error_text,
.login_desc,
.login_title {
  font-weight: 400 !important;
}
.loading_text {
  font-size: 20px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.01em;
  color: variable.$white-color;
}

.main_title {
  font-size: 24px;
  line-height: 24px !important;
  letter-spacing: 0.06em;
  color: #000000;
  text-transform: uppercase !important;
}
.main_title.transUnset {
  text-transform: unset !important;
}
.title_lg {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: variable.$black-light-color;

  @include mixin.smallDesk {
    line-height: 18px;
    font-size: 18px;
  }
}
.title_md {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: variable.$black-light-color;

  @include mixin.ipad {
    line-height: 20px;
    font-size: 17px;
  }
}
.title_sm,
.title_sm2,
.title_sm_x2 {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: variable.$black-light-color;

  @include mixin.smallDesk {
    line-height: 20px;
    font-size: 14px;
  }
}
.title_sm2 {
  font-size: 13px;
}
.title_sm_x2 {
  font-size: 14px;
}
.hint_sm {
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: variable.$gray-font-color;

  @include mixin.smallDesk {
    line-height: 18px;
  }
}
.tooltip_field {
  margin-bottom: -10px;
  margin-left: 10px;
}
.tooltip_field2 {
  margin-bottom: 10px;
  margin-left: 10px;
}
.tooltip_field3 {
  position: absolute;
  top: 45px;
  right: 57.5%;
}

.num_lg {
  font-weight: 700;
  font-size: 24px;
  line-height: 48px;
  letter-spacing: -0.03em;
  color: variable.$black-light-color;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  font-size: 20px;
  color: #fff;
  filter: contrast(0.1);
}
/* input[type="date"]::-webkit-datetime-edit {
  color: transparent;
} */
input[type="date"]:focus::-webkit-datetime-edit {
  color: #000;
}
select.arrowDisable {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}
.calc_input input,
.calc_input input::placeholder {
  font-size: 18px !important;
  padding-left: 0 !important;
  width: auto !important;
}
.calc_input div[class*="forms_form_control"] {
  width: auto !important;

  label {
    display: none !important;
  }
}
.table_content {
  > div {
    overflow: visible !important;
  }
  width: 100%;
  div[role="columnheader"] {
    span.active {
      opacity: 1;
      color: transparent;
      margin-left: 3px;
      background-image: url("../images/icons/sort(1).png");
      background-position: center center;
      background-size: 15px;
      background-repeat: no-repeat;
    }
    span:not(.active) {
      opacity: 1;
      color: transparent;
      margin-left: 3px;
      background-image: url("../images/icons/sort(1).png");
      background-position: center center;
      background-size: 15px;
      background-repeat: no-repeat;
      transform: none;
    }
  }

  .rdt_TableHead {
    > div {
      background: #f7f7f7;
      border: none !important;
    }
  }

  .rdt_TableHeadRow {
    > div[data-column-id="1"] {
      > div {
        padding-left: 20px;
      }
    }
    > div:last-of-type {
      flex-grow: 0.6;
    }

    div:hover {
      opacity: 1;
    }
  }
  .rdt_TableBody {
    > div > div:last-of-type {
      flex-grow: 0.6;
    }
    div > div[data-column-id="1"] > div {
      padding-left: 20px;
    }
  }

  .rdt_TableRow {
    border: none !important;
    min-height: 53px;
    background: #f7f7f7;

    &:nth-child(odd) {
      background: #fcfcfc;
    }
    div:not(:last-child) {
      // pointer-events: none;

      div {
        // pointer-events: none;
      }
    }
  }

  .rdt_Pagination {
    margin-bottom: -65px;
    padding-top: 30px;
    background: transparent;
    justify-content: center;

    //.lmSJmf,
    .gMliOo,
    .bueHSC,
    #pagination-first-page,
    #pagination-last-page {
      display: none;
    }

    .lmSJmf {
      position: absolute;
    }

    #pagination-previous-page {
      margin-right: 50px;
    }
    #pagination-previous-page,
    #pagination-next-page {
      background: #ffffff;
      border: 1px solid #e2e2ea;
      border-radius: 5px;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px;
    }

    /* .hoQsHK::before{
      content
    } */
  }
}

.asset_main_table {
  .rdt_TableRow {
    div:not(:last-child) {
      pointer-events: all;

      div {
        pointer-events: all;
      }
    }
    // div:not(:first-child) {
    //   pointer-events: none;

    //   div {
    //     pointer-events: none;
    //   }
    // }
  }
}

.datepicker_year {
  .react-datepicker__navigation--next {
    background: url("../images/icons/datepickerArrowRight.svg") no-repeat;
    width: 15px;
    height: 15px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center right;
    margin-right: 13px;
  }
  .react-datepicker__navigation--previous {
    background: url("../images/icons/datepickerArrowLeft.svg") no-repeat;
    width: 15px;
    height: 15px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center left;
    margin-left: 13px;
  }

  .react-datepicker-popper {
    background-color: #fff;
  }
  .react-datepicker {
    display: flex;
    flex-wrap: wrap;
    max-width: 320px;
    background-color: #fcfcfc;
    border: 1px solid #efefef;
    box-shadow: 0px 12px 38px -8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  .react-datepicker__navigation.react-datepicker__navigation--previous,
  .react-datepicker__navigation.react-datepicker__navigation--next {
    width: calc(50% - 13px);
    min-height: 60px;
    position: relative;
  }
  .react-datepicker__navigation-icon.react-datepicker__navigation-icon--next,
  .react-datepicker__navigation-icon.react-datepicker__navigation-icon--previous {
    color: transparent;
  }
  .react-datepicker__navigation.react-datepicker__navigation--previous::after {
    content: "";
    bottom: 0%;
    right: -90%;
    width: 180%;
    height: 1px;
    background-color: #efefef;
    position: absolute;
  }

  /* .react-datepicker__navigation.react-datepicker__navigation--previous::before {
  content: "<";
  right: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  font-size: 25px;
  font-weight: 300;
  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
} */
  .react-datepicker__navigation.react-datepicker__navigation--previous {
    color: #fcfcfc;
    margin-right: auto;
  }
  .react-datepicker__navigation.react-datepicker__navigation--next::after {
    content: "";
    bottom: 0%;
    left: -90%;
    width: 180%;
    height: 1px;
    background-color: #efefef;
    position: absolute;
  }
  .react-datepicker__navigation.react-datepicker__navigation--next {
    color: #fcfcfc;
    margin-left: auto;
  }
  /* .react-datepicker__navigation.react-datepicker__navigation--next::before {
  content: ">";
  right: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  font-size: 25px;
  font-weight: 300;
  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
} */
  .react-datepicker__year-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .react-datepicker__year-text {
    padding: 8px 15px;
    cursor: pointer;
  }
  .react-datepicker__header.react-datepicker-year-header {
    text-align: center;
    position: relative;
    margin-top: -42px;
    color: #fcfcfc;
    pointer-events: none;
  }
  .react-datepicker__header.react-datepicker-year-header::before {
    content: "Select a year";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    /* identical to box height, or 160% */

    letter-spacing: -0.02em;
  }
  .react-datepicker__year {
    margin-top: 35px;
    padding-bottom: 15px;
  }
  .react-datepicker-popper {
    z-index: 9;
  }
  .react-datepicker__year--container {
    padding: 0 10px;
  }
  .react-datepicker__year-text.react-datepicker__year-text--disabled {
    cursor: not-allowed;
    color: variable.$gray-font-color;
  }
}
.datepicker_year_month {
  .react-datepicker__navigation--next {
    background: url("../images/icons/datepickerArrowRight.svg") no-repeat;
    width: 15px;
    height: 15px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center right;
    margin-right: 13px;
  }
  .react-datepicker__navigation--previous {
    background: url("../images/icons/datepickerArrowLeft.svg") no-repeat;
    width: 15px;
    height: 15px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center left;
    margin-left: 13px;
  }

  .react-datepicker-popper {
    background-color: #fff;
  }
  .react-datepicker {
    display: flex;
    flex-wrap: wrap;
    //max-width: 320px;
    max-width: 230px;
    background-color: #fcfcfc;
    border: 1px solid #efefef;
    box-shadow: 0px 12px 38px -8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  .react-datepicker__month,
  .react-datepicker__month-container {
    width: 100%;
  }
  .react-datepicker__month-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .react-datepicker__month-text {
      width: 33.333%;
      text-align: center;
    }
  }
  .react-datepicker__navigation.react-datepicker__navigation--previous,
  .react-datepicker__navigation.react-datepicker__navigation--next {
    width: calc(50% - 13px);
    min-height: 60px;
    position: relative;
  }
  .react-datepicker__navigation-icon.react-datepicker__navigation-icon--next,
  .react-datepicker__navigation-icon.react-datepicker__navigation-icon--previous {
    color: transparent;
  }
  .react-datepicker__navigation.react-datepicker__navigation--previous::after {
    content: "";
    bottom: 0%;
    right: 0%;
    width: 90%;
    height: 1px;
    background-color: #efefef;
    position: absolute;
  }

  /* .react-datepicker__navigation.react-datepicker__navigation--previous::before {
  content: "<";
  right: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  font-size: 25px;
  font-weight: 300;
  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
} */
  .react-datepicker__navigation.react-datepicker__navigation--previous {
    color: #fcfcfc;
    margin-right: auto;
  }
  .react-datepicker__navigation.react-datepicker__navigation--next::after {
    content: "";
    bottom: 0%;
    left: 0%;
    width: 90%;
    height: 1px;
    background-color: #efefef;
    position: absolute;
  }
  .react-datepicker__navigation.react-datepicker__navigation--next {
    color: #fcfcfc;
    margin-left: auto;
  }
  /* .react-datepicker__navigation.react-datepicker__navigation--next::before {
  content: ">";
  right: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  font-size: 25px;
  font-weight: 300;
  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
} */
  .react-datepicker__month-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .react-datepicker__month-text {
    padding: 8px 15px;
    cursor: pointer;
  }
  .react-datepicker__header.react-datepicker-year-header {
    text-align: center;
    position: relative;
    margin-top: -42px;
    //color: #fcfcfc;
    pointer-events: none;
  }
  .react-datepicker__header.react-datepicker-month-header::before {
    content: "Select a year";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    /* identical to box height, or 160% */

    letter-spacing: -0.02em;
  }
  .react-datepicker__month {
    margin-top: 35px;
    padding-bottom: 15px;
  }
  .react-datepicker-popper {
    z-index: 9;
  }
  .react-datepicker__month--container {
    padding: 0 10px;
  }
  .react-datepicker__month-text.react-datepicker__month-text--disabled {
    cursor: not-allowed;
    color: variable.$gray-font-color;
  }
}

.mt5 {
  margin-top: 5px !important;
}
.glider-slide {
  min-width: auto !important;
}
.slide {
  margin: 0 10px;
}
.w_80 {
  width: 80%;
}
.w_70 {
  width: 70%;
}

.accord {
  .chakra-collapse {
    overflow: visible !important;
  }
  .chakra-table__container {
    overflow: visible !important;
  }
}

.activeH2 {
  //box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.05), inset 0px 1px 1px variable.$white-color;
  //background-color: variable.$gray-light-color;
  border-radius: 5px;
  box-shadow: inset 0px -2px 1px rgb(0 0 0 / 5%), inset 0px 1px 1px #fff;
  background-color: #efefef;

  div {
    color: variable.$black-light-color !important;
  }

  svg:first-of-type {
    fill: variable.$black-light-color;

    path {
      fill: variable.$black-light-color;
    }
  }
}
.closed {
  .activeH2 {
    box-shadow: inset 0px -2px 1px rgb(0 0 0 / 5%), inset 0px 1px 1px #fff;
    background-color: #efefef;
  }
}
.activeH3 {
  border-radius: 5px;
  box-shadow: inset 0px -2px 1px rgb(0 0 0 / 5%), inset 0px 1px 1px #fff;
  background-color: #efefef;

  div {
    color: variable.$black-light-color !important;
  }

  svg:first-of-type {
    fill: variable.$black-light-color;

    path {
      fill: variable.$black-light-color;
    }
  }
}
.closed {
  .activeH3 {
    box-shadow: inset 0px -2px 1px rgb(0 0 0 / 5%), inset 0px 1px 1px #fff;
    background-color: #efefef;
  }
}
.notif_dropdown {
  width: 350px;
  padding: 15px 10px 20px 10px !important;
  background: #ffffff;
  box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.05) !important;
  border-radius: 16px !important;
  border: none;

  .notif_dropdown_body {
    max-height: 350px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgb(226, 226, 226);
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgb(226, 226, 226);
    }
  }
}
.notif_badge {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 18px;
  height: 18px;
  background: #DE350B;
  border-radius: 50%;
  display: block;
  font-size: 12px;
  line-height: 16px;
  color: white;
  text-align: center;
}
span.masterDataBadge {
  text-transform: none;
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 4px;
  font-weight: 400;
}

.main_tabs {
  border-radius: 5px;
  height: 40px;
  //width: 143px;
  font-size: 14px !important;
  font-weight: 400;
  background-color: #fff;
  border-color: #eaeaea !important;
}
.eVPPvF {
  position: unset !important;
}
/* .WQNxq {
  overflow: visible !important;
} */

a.btn_primary,
button.btn_primary {
  background-color: variable.$gold-color;
  color: variable.$black-light-color;
  border-radius: 5px;
  padding: 12px 33px;
  border: 0;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  transition: all 0.5s ease-in-out;

  &:hover {
    background-color: variable.$gold-darker-color;
  }

  @include mixin.smallDesk {
    line-height: 18px;
    font-size: 14px;
  }
}

@include mixin.smallDesk {
  .tooltip_field3 {
    position: absolute;
    top: 35px;
    right: 57.2%;
  }
}

.chakra-accordion__panel {
  border-top: 0 !important;
  border-color: #e6e6e6 !important;

  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  // margin-bottom: -10px;

  > div p {
    @include mixin.smallDesk {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}

.checkbox_input {
  .chakra-checkbox__control {
    //background-color: ;
    border: 2px solid rgba(111, 118, 126, 0.4);
    border-radius: 6px;
    width: 24px;
    height: 24px;

    svg {
      stroke: white !important;
    }
  }
}
.checkbox_input_sm {
  .chakra-checkbox__control {
    //background-color: ;
    border: 2px solid rgba(111, 118, 126, 0.4);
    border-radius: 6px;
    width: 16px;
    height: 16px;

    svg {
      stroke: white !important;
    }
  }
}
.modal_full {
  .chakra-modal__header {
    padding-left: 0px;
    padding-right: 0;
  }
  .chakra-modal__close-btn {
    display: none;
  }
  .chakra-modal__header {
    .chakra-modal__close-btn {
      display: flex;
    }
  }
}
.comment_box {
  img {
    object-fit: cover;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid variable.$gray-light-color;
  }
}

.drawer_content {
  width: 551px;
  min-width: 551px;
}
.accordion_main {
  border: none;

  h2 {
    border: none;

    button {
      border: none;
      height: 72px;
      border-top: 3px solid #ebc03f;
      border-radius: 5px;
      background-color: #fff;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  > div {
    background-color: #fff;

    > div {
      border: 0;
    }
  }
}
.table_main {
  tbody {
    tr:nth-child(odd) {
      background-color: #f9f9f9;
    }
    td {
      padding-top: 0px;
      padding-bottom: 0px;
      border: none;
    }
  }
}
.table_main.calc_table {
  tbody {
    tr:nth-child(odd) {
      background-color: #fff;
    }
    tr {
      border-bottom: 1px solid #e9e9e9;
    }
    /* tr td:not(:last-of-type) {
      border-right: 1px solid #e9e9e9;
    } */
    tr td {
      border-right: 1px solid #e9e9e9;
      width: 16.6666%;
    }
  }
  div {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  div[class*="forms_form_control"] {
    width: auto !important;
    min-width: 80px;
    margin-right: 3px;
    margin-left: 3px;
  }
  // div[class*="forms_form_control_select"] {
  //   overflow: visible !important;
  //   position:absolute;
  //   z-index: 99999;

  // }
}

.asset_stepper {
  max-width: 380px !important;
}
.note_wrapper {
  margin-left: 0 !important;
}
.m_0 {
  margin-left: 0 !important;
}

.ag-header-cell,
.ag-cell-value {
  font-weight: 400;
  padding-right: 50px !important ;
  font-size: 12px;
}
.ag-header-cell {
  font-size: 12px;
}
.ag-cell-value {
  font-size: 14px;
}
.ag-root-wrapper {
  min-height: auto !important;
  width: 100% !important;
  margin-bottom: 25px;
}
.ag-row-even {
  background-color: #fcfcfc;
  border: 0 !important;
}
.ag-row-odd,
.ag-header-row {
  background-color: #f7f7f7;
  border: 0 !important;
}
.ag-header,
.ag-root-wrapper.ag-layout-normal {
  border: 0 !important;
  overflow: visible;
}
.ag-body-horizontal-scroll-viewport {
  display: none;
}
.ag-root-wrapper-body.ag-layout-normal {
  height: 100%;
}
.ag-root-wrapper-body.ag-layout-normal {
  // height: 553px;
  // max-height: 553px;
  min-height: 200px;
}
.ag-root.ag-layout-normal {
  min-height: 185px;
}

.masterData_table.active {
  .ag-root-wrapper-body.ag-layout-normal {
    .ag-root.ag-layout-normal {
      // overflow-y: visible;

      &::-webkit-scrollbar {
        width: 5px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: rgb(226, 226, 226);
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: rgb(226, 226, 226);
      }
    }
  }
}
.masterData_table_custom.active {
  .ag-header-row .ag-header-cell:not(:first-of-type),
  .ag-row .ag-cell-value:not(:first-of-type)/* ,
  .ag-header-cell:not(:last-of-type) */ {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  // .ag-root-wrapper-body.ag-layout-normal {
  //   .ag-root.ag-layout-normal {
  //     // overflow-y: visible;

  //     &::-webkit-scrollbar {
  //       width: 0px;
  //     }
  //     /* Track */
  //     &::-webkit-scrollbar-track {
  //       background: #f1f1f1;
  //     }
  //     /* Handle */
  //     &::-webkit-scrollbar-thumb {
  //       background: rgb(226, 226, 226);
  //     }
  //     /* Handle on hover */
  //     &::-webkit-scrollbar-thumb:hover {
  //       background: rgb(226, 226, 226);
  //     }
  //   }
  // }
  // .ag-root-wrapper {
  //   padding-bottom: 110px;
  // }
  .ag-center-cols-viewport {
    overflow: visible;
    overflow-x: hidden;
  }
  .ag-center-cols-viewport {
    // padding-bottom: 110px;
    // overflow-y: visible;
    // background: #fcfcfc;

    &::-webkit-scrollbar {
      width: 5px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgb(226, 226, 226);
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgb(226, 226, 226);
    }
  }
}

// .ag-center-cols-viewport {
//   overflow-y: visible;

//   &::-webkit-scrollbar {
//     width: 5px;
//   }
//   /* Track */
//   &::-webkit-scrollbar-track {
//     background: #f1f1f1;
//   }
//   /* Handle */
//   &::-webkit-scrollbar-thumb {
//     background: rgb(226, 226, 226);
//   }
//   /* Handle on hover */
//   &::-webkit-scrollbar-thumb:hover {
//     background: rgb(226, 226, 226);
//   }
// }
//to show checkbox in table
/* .ag-cell.ag-cell-normal-height.ag-cell-not-inline-editing input {
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.ag-cell.ag-cell-normal-height.ag-cell-inline-editing input {
  opacity: 1;
  border: 1px solid #f2f2f2 !important;
} */
/* .ag-cell-normal-height{
  overflow: visible !important;
}
 */

[aria-colindex="2"] {
  overflow: visible !important;
}
.select_disabled {
  img,
  svg,
  div[role="button"] {
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
}
.select_undisabled {
  img,
  svg,
  div[role="button"] {
    opacity: 1;
  }
}

.ag-body-viewport.ag-layout-normal {
  //scrollbar style
  overflow: hidden;
  // overflow: visible;

  &::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(226, 226, 226);
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgb(226, 226, 226);
  }
}
.scroll_bar_h {
  &::-webkit-scrollbar {
    height: 4px; /* height of horizontal scrollbar ← You're missing this */
    width: 4px; /* width of vertical scrollbar */
    border: 1px solid #d5d5d5;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(226, 226, 226);
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgb(226, 226, 226);
  }
}

.inactive-class > div {
  color: grey !important;
  opacity: 0.8;
}
.active-class > div {
  color: #1a1d1f !important;
}
.chakra-switch {
  --switch-track-width: 35px !important;
  min-width: 120px !important;
  width: 120px !important;
  border-radius: 30px !important;
}
.chakra-switch2 .chakra-switch__track[data-checked] {
  background-color: #2a85ff;
}
.chakra-switch2 .chakra-switch__track[data-disabled] {
  background-color: #2a85ff;
}

.ag-icon {
  background: transparent url("../images/icons/Arrange.svg") center/contain
    no-repeat;
  color: transparent;
  width: 20px;
  height: 20px;
}

.ag-header-cell,
.ag-cell-value {
  padding-left: 35px !important;
}
.ag-dnd-ghost {
  opacity: 0;
}
.grid_full {
  grid-column-start: 1;
  grid-column-end: 7;
}
.ag-theme-alpine {
  opacity: 0;

  &.show_table {
    opacity: 1;
  }
  /* bright green, 10% opacity */
  --ag-range-selection-border-color: transparent;
  --ag-input-focus-border-color: #efefef;
  --ag-row-hover-color: none;
  .ag-sort-indicator-container {
    position: relative;
  }
  .ag-sort-indicator-icon.ag-sort-ascending-icon {
    position: absolute;
    top: -7px;
    z-index: 4;
    .ag-icon {
      background-image: url("../images/icons/sort(1).png");
      width: 15px;
      height: 15px;
    }
  }

  .ag-sort-indicator-icon.ag-sort-descending-icon {
    position: absolute;
    top: -7px;
    z-index: 4;
    .ag-icon {
      transform: rotate(180deg);
      background-image: url("../images/icons/sort(1).png");
      width: 15px;
      height: 15px;
    }
  }
  .ag-sort-indicator-icon.ag-sort-none-icon.ag-hidden {
    z-index: 2;
    position: absolute;
    top: -7px;
    display: inline !important;
    .ag-icon {
      transform: rotate(180deg);
      background-image: url("../images/icons/sortIcon.png");
      width: 15px;
      height: 15px;
      margin-left: 0.7px;
    }
  }
}
// .ag-row {
//   //z-index: 9999999999;
//   //overflow: visible;
// }
.ag-row-odd.ag-row.ag-row-level-0.ag-row-position-absolute.ag-row-focus.ag-row-not-inline-editing,
.ag-row-even.ag-row.ag-row-level-0.ag-row-position-absolute.ag-row-first.ag-row-focus.ag-row-not-inline-editing,
.ag-row-even.ag-row.ag-row-level-0.ag-row-position-absolute.ag-row-focus.ag-row-not-inline-editing {
  z-index: 9999999999;
  overflow: visible;
}
.ag-input-field-input,
.ag-text-field-input,
.ag-cell-inline-editing {
  border: transparent !important;
  border-radius: 2px !important;
  padding: 5px 2px !important;
  margin-left: 14px !important;
  width: auto !important;
  box-shadow: none !important;
  // width: 220px !important;
  // text-align: center !important;
}
.ag-cell-inline-editing {
  border: 1px solid #e6e6e6 !important;
  background-color: #fff !important;
}
.ag-cell-inline-editing {
  box-shadow: none;
  background-color: transparent;
}
.btn_submit {
  background-color: variable.$gold-color;
  color: variable.$black-light-color;
  border-radius: 5px;
  padding: 12px 20px;
  border: 0;
  font-size: 15px;
  line-height: 24px;
  &:hover {
    cursor: pointer;
  }
}
.btn_submit img {
  margin-inline-end: 10px;
}
#main_content {
  scroll-behavior: smooth;
}
.justify_content_start {
  justify-content: flex-start;
}
.accordion_item_custom {
  .chakra-collapse {
    overflow: visible !important;
  }
}
.accordion_item_custom.accordion_item_blue_custom {
  h2 button:hover {
    background-color: #003865;
  }
  h2 svg path {
    fill: #fff;
  }

  .chakra-accordion__panel {
    background-color: rgba(0, 56, 101, 0.06);
  }
}
#chakra-toast-manager-top-right {
  top: 75px !important;
  right: 25px !important;
}
.radio_group_custom {
  label {
    width: 120px;
    height: 48px;
    border: 2px solid #efefef;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 15px;
    }
  }
}
.select2 {
  width: 330px;
  margin-left: -45px;
  font-size: 10px;
  //  }
  //  .select2>div{
  //   height:40px;
  //  }
  //  .select2>div>div{
  //   height:40px;
  //   overflow: visible;
}
.table_region {
  .ag-root-wrapper {
    .ag-body-viewport {
      .ag-center-cols-container {
        .ag-row {
          .ag-cell:nth-child(4) {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: -20px;
          }
        }
      }
    }
  }
}
.ml_0 {
  margin-left: 0px !important;
}
.model_card-create {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23E0E5E9FF' stroke-width='3' stroke-dasharray='4%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e") !important;
  border-radius: 5px;
}

.table_content {
  .chakra-accordion__item {
    border: none;
    h2 {
      border: none;
      button[aria-expanded="false"] {
        border-radius: 5px;
        border: 1px solid #e6e6e6;
      }
      button[aria-expanded="true"] {
        border: 1px solid #e6e6e6;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
    }
  }
}
.progress_class > div {
  background-color: #6ad6c5;
  transition: all 0.6s ease-in-out;
}
.dashed_outline {
  // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='7' ry='7' stroke='%23828282FF' stroke-width='1' stroke-dasharray='5' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 7px;
  border: 1.8px dashed #d3d3d3;
}
.addfieldSec {
  width: calc(100% - 38px);
}
.gridRowGap0 {
  grid-gap: 0 !important;
}

.errorNew {
  color: variable.$red-light-color;
  margin-top: -15px;
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.04em;
}

.ag-ltr {
  overflow: hidden;
}

.homepage-kpi-tablist > .homepage-kpi-tab[aria-selected="true"] {
  background: variable.$gold-color;
  color: variable.$white-color;
}

@include mixin.mdDesk {
  .asset_stepper {
    max-width: 330px !important;
  }
  .table_region {
    .ag-root-wrapper {
      .ag-body-viewport {
        .ag-center-cols-container {
          .ag-row {
            .ag-cell:nth-child(4) {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 1.3%;
            }
          }
        }
      }
    }
  }
}
input[disabled] {
  // color: #6f767e;
  color: #9099a4 !important;
}
textarea[disabled] {
  // color: #6f767e;
  color: #9099a4 !important;
}
div[class*="option"] {
  //background-color: red !important;
  width: 100% !important;
  min-width: 100% !important;
  //padding: 20px !important;
  cursor: pointer;
}
.lable_fixed_height {
  label {
    line-height: 1.2;
    height: 24px;
  }
}
.tooltip_field5 {
  width: 15px;
  margin-left: 4px;
}
@include mixin.smallDesk {
  .asset_stepper {
    max-width: 320px !important;
  }
  .lable_fixed_height {
    label {
      line-height: 1.2;
      height: 18px;
    }
  }
  #chakra-toast-manager-top-right {
    top: 70px !important;
    right: 25px !important;
  }

  .table_region {
    .ag-root-wrapper {
      .ag-body-viewport {
        .ag-center-cols-container {
          .ag-row {
            .ag-cell:nth-child(4) {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: -20px;
            }
          }
        }
      }
    }
  }
  .chakra-switch2 .chakra-switch__track[data-disabled] {
    background-color: #2a85ff;
    margin-left: -30px !important;
  }
  .tooltip_field4 {
    margin-bottom: 22px !important;
    margin-left: 15px;
  }
  .tooltip_field5 {
    width: 15px;
    margin-left: 4px;
  }

  .chakra-switch {
    --switch-track-width: 35px !important;
    min-width: 20px !important;
    width: 20px !important;
    border-radius: 30px !important;
  }
  .mt--10 {
    margin-top: -18px;
  }

  .accordion_main {
    h2 {
      button {
        height: 64px;

        p {
          font-size: 16px;
        }
      }
    }
  }
  .drawer_content {
    width: 430px;
    min-width: 430px;
  }
  .model_card {
    padding: 20x 10px !important;
    margin-bottom: 25px !important;
  }
  .iconic_left_input {
    top: 10px;
  }
  button.b_48px {
    height: 41px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  // .ag-root-wrapper-body.ag-layout-normal {
  //   height: 469px;
  //   max-height: 469px;
  //   min-height: 200px;
  // }
  .iconic_input {
    top: 11.5px;
    img {
      max-width: 19px;
    }
  }
  .iconic_input_small {
    top: 11.5px;
    img {
      max-width: 19px;
    }
  }
  .iconic_input_small2 {
    top: 10.5px;
  }

  .btn_profile {
    img {
      width: 20px;
      height: 20px;
    }
    img[alt="Profile"] {
      width: 28px;
      height: 28px;
    }
  }
  .btn_notif {
    img {
      width: 20px;
      height: 20px;
    }
  }
  .notif_badge {
    top: 2px;
    right: 2px;
    width: 7px;
    height: 7px;
  }
  .title_md {
    font-size: 15px !important;
  }
  .main_box {
    padding: 10px 15px !important;
  }

  .table_content {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  span.masterDataBadge {
    font-size: 11px;
  }
  .modal_content {
    max-width: 480px !important;

    .modal_header {
      font-size: 18px !important;
    }
    .info_icon {
      width: 66px;
      height: 66px;
    }
    .b_45px {
      height: 41px;
    }
  }
  .table_content {
    .chakra-accordion__item {
      h2 p {
        font-size: 14px;
      }
    }
  }
  .link_box {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
}
.form {
  width: 100%;
}

@include mixin.mobile {
  .btn_primary {
    width: 100%;
  }
}
/* .form-group-four-input .form-control label ~ .form-control-input {
  background: black;
  width: calc(100% - 100px);
} */
.table_prev {
  transform: rotate(180deg);
}
.Embed-container {
  height: 100vh;
  width: 100%;
}

@include mixin.mobile {
  .Embed-container {
    margin: 0 -35px;
    width: 100vw;
  }

  .Embed-frame {
    margin-top: -40px;
  }
}

.textUnderLine:hover {
  text-decoration: underline;
}