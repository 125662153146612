
.ag_format_container {
    // width: 1000px;
    margin: 0 auto;
  }
  
  

  .ag_courses_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  
    padding: 50px 0;
  }
  .ag_courses_item {
    -ms-flex-preferred-size: calc(25% - 30px);
    flex-basis: calc(25% - 30px);
  
    // margin: 0 15px 30px;
  
    overflow: hidden;
  
    border-radius: 28px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border: 3px solid #ebc03f; /* Border style */

  }

  .ag_courses_item_link {
    display: block;
    padding: 20px 10px;
    background-color: #fff;
    // height: 500px;
  
    overflow: hidden;
  
    position: relative;
    height: 80%;
    border-radius:25px;
    cursor: pointer;
  }
  .ag_courses_item_link:hover,
  .ag_courses_item_link:hover .ag_courses_item_date {
    text-decoration: none;
    color: #FFF;
  }
  .ag_courses_item_link:hover .ag_courses_item_bg {
    -webkit-transform: scale(10);
    -ms-transform: scale(10);
    transform: scale(10);
  }
  .ag_courses_item_title {
    min-height: 53px;
    margin: 0 0 0px;
    font-weight: bold;
    overflow: hidden;

    // color: #FFF;
  
    //z-index: 2;
    position: relative;
  }
  .ag_courses_item_date_box {
    font-size: 18px;
    // color: #FFF;
  
    //z-index: 2;
    position: relative;
  }
  .ag_courses_item_date {
    font-weight: bold;
    color: #ebc03f;
  
    -webkit-transition: color .5s ease;
    -o-transition: color .5s ease;
    transition: color .5s ease
  }
  .ag_courses_item_bg {
    height: 128px;
    width: 128px;
    background-color: #f3de9f;
  
    // //z-index: 1;
    position: absolute;
    top: -75px;
    right: -75px;
  
    border-radius: 50%;
  
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
  }
  // .ag_courses_item:nth-child(2n) .ag_courses_item_bg {
  //   background-color: #3ecd5e;
  // }
  // .ag_courses_item:nth-child(3n) .ag_courses_item_bg {
  //   background-color: #e44002;
  // }
  // .ag_courses_item:nth-child(4n) .ag_courses_item_bg {
  //   background-color: #952aff;
  // }
  // .ag_courses_item:nth-child(5n) .ag_courses_item_bg {
  //   background-color: #cd3e94;
  // }
  // .ag_courses_item:nth-child(6n) .ag_courses_item_bg {
  //   background-color: #4c49ea;
  // }
  
  
  
  @media only screen and (max-width: 979px) {
    .ag_courses_item {
      -ms-flex-preferred-size: calc(50% - 30px);
      flex-basis: calc(50% - 30px);
    }
    .ag_courses_item_title {
      font-size: 24px;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .ag_format_container {
      width: 100%;
    }
  
  }
  @media only screen and (max-width: 639px) {
    .ag_courses_item {
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
    }
    .ag_courses_item_title {
      min-height: 72px;
      line-height: 1;
  
      font-size: 24px;
    }
    .ag_courses_item_link {
      padding: 22px 40px;
    }
    .ag_courses_item_date_box {
      font-size: 16px;
    }
  }

  .ag_courses_item_link {
    display: relative; /* Ensure the anchor tag is relatively positioned */
    position: relative; /* Ensure the anchor tag is relatively positioned */
}

.ag_checkbox {
    margin-right: 5px; /* Adjust margin as needed */
}

.checked {
  border-color: #80B5A1; /* Change border color when checked */
}


.ag_courses_item_bg_selected {
  height: 128px;
  width: 128px;
  background-color: #80B5A1;

  //z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;

  border-radius: 50%;

  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
  color: #FFF;
}

// .ag_courses_item_link:hover .ag_courses_item_bg_selected {
//   -webkit-transform: scale(10);
//   -ms-transform: scale(10);
//   transform: scale(10);
// }


.ag_courses_item_content {
  display: flex;
  align-items: center; /* Align items vertically */
  margin-top: 10px;
}

.icon_wrapper {
  margin-right: 5px; /* Adjust spacing between icon and text */
}

.ag_courses_item_text {
  /* Add any additional styling for text if necessary */
}








.container {
  display: grid;
  grid-template-columns: auto auto; /* Auto-size columns */
  align-items: center; /* Align items vertically center */
}

.container img,
.container p {
  margin: 0; /* Remove default margin */
}


.fixedContent {
  position: fixed;
  top: 70;
  left: 100;
  width: 100%;
  z-index: 1000;
  /* Add any additional styling properties you need */
}

.button {
  font: inherit;
  line-height: 1;
  background-color: var(--c-white);
  border: 2px solid var(--c-indigo);
  color: var(--c-indigo);
  padding: 0.5em 1em;
  border-radius: 6px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  &:hover,
  &:focus {
    background-color: var(--c-indigo);
    color: var(--c-white);
  }
}



@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;600&display=swap');

$transition-time: 0.6s; // Adjusted for a quicker flip effect
$perspective: 1000px;

.card {
  font-family: 'Oswald', sans-serif;
  position: relative;
  width: 100%; // Width of the card
  height: 400px; // Height of the card
  perspective: $perspective;
  margin: 20px 0;

  &:hover .card-inner {
    transform: rotateY(180deg);
  }
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform $transition-time;
  transform-style: preserve-3d;
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; // This property is crucial for the flip effect
}

.card-front {
  // Your styles for the front of the card
}

.card-back {
  // Your styles for the back of the card
  transform: rotateY(180deg); // Flip the back card to the back on initial load
  background-color: #0b0f08;
  display: flex;
  align-items: center;
  justify-content: center;
}

// ... Rest of your styles
