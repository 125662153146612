@use "../../assets/styles/variables" as variable;
@use "../../assets/styles/mixin" as mixin;

.navbar {
  height: 70px;
  width: calc(100% - 88px);
  margin-left: calc(100% - 88px);
  background-color: variable.$gray-lighter-color;
  //box-shadow: inset 1px 0px -1px #f4f4f4;
  border-bottom: 2px solid variable.$gray-light-color;
  padding: 10px 20px 10px 20px;
  display: flex;
  align-items: center;
  //position: absolute;
  //if we want it to be fixed without animation
  position: fixed;
  right: 0;
  z-index: 41;
  div:first-of-type img[alt="logo"] {
    // width: 60px;
    width: 66px;
  }
  transition: all 0.2s ease-in-out;
}

@include mixin.smallDesk {
  .navbar {
    height: 65px;
    width: calc(100% - 73px);
    margin-left: calc(100% - 75px);

    div:first-of-type img[alt="logo"] {
      // width: 60px;
      width: 62px;
    }
  }
}
/* @include mixin.smallDesk {
  .navbar {
    padding: 10px 50px 10px 55px;
  }
} */
