@font-face {
  font-family: "Brown";
  src: url(../fonts/FontsFree-Net-Brown-Regular-Regular.ttf);
}
@font-face {
  font-family: "Brown";
  src: url(../fonts/Brown-Bold.ttf);
  font-weight: bold;
}

*,
body,
html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Brown", sans-serif !important;
  //font-family: 400 !important;
  font-family: 400;
  color: variable.$black-light-color;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
