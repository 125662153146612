@use "../../assets/styles/mixin" as mixin;
@use "../../assets/styles/variables" as variable;

.info_card {
  .background_text {
    color: variable.$gray-bold-color;
  }
  &.border_summary_box {
    border: 2px solid #efefef !important;
  }
}