@use "../../assets/styles/variables" as variable;
@use "../../assets/styles/mixin" as mixin;

@include mixin.mobile {
  .container {
    padding: 114px 35px 20px 35px !important;
  }
}

.content {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  position: relative;
  flex: 1;
  background-color: variable.$primary-color;
}

.container {
  width: 100%;
  padding: 114px 35px 20px 125px;
}

.navbar_scroll {
  position: fixed !important;
  animation: scNav 1s both cubic-bezier(0.075, 0.82, 0.165, 1);
}

@keyframes scNav {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

@media only screen and (min-width: 1900px) {
  .container > div {
    max-width: 1650px;
    margin: 0 auto;
  }
}

@include mixin.smallDesk {
  .container {
    //padding: 130px 65px 20px 150px;
    padding: 100px 35px 20px 115px;
  }
  .container h2 {
    font-size: 20px;
    /* padding-top: 5px;
    padding-bottom: 17px; */
  }
}
