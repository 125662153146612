@use "../../assets/styles/variables" as variable;
@use "../../assets/styles/mixin" as mixin;

@include mixin.mobile {
  .wrapper.closed {
    width: 0 !important;
    padding: 0 !important;
    height: 65px !important;
  }

  .btn_burger_wrapper {
    width: 90px !important;
  }
}

.wrapper {
  //width: 360px;
  width: 90px;
  height: 100vh;
  //background-color: variable.$black-color;
  //padding: 50px 40px;
  position: fixed;
  top: 0px;
  z-index: 29;
  overflow-x: hidden;
  overflow-y: auto;
  cursor: pointer;
  border-right: 2px solid variable.$gray-light-color;

  //scrollbar style
  &::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(226, 226, 226);
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgb(226, 226, 226);
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      color: variable.$white-color;
      padding: 10px;
      border-bottom: 2px solid rgb(218, 218, 218);
    }
  }

  .list_items {
    display: flex;
    align-items: center;
    &:hover {
      border-radius: 5px !important;
    }
    p {
      width: 230px !important;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: variable.$gray-font-color;
      margin-left: 25px;
    }

    &:first-of-type {
      border-radius: 5px;
    }
  }

  .accord_item {
    border: none;

    h2 {
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: -0.01em;
      border: none;

      div {
        font-size: 15px;
        font-weight: 400;
        letter-spacing: -0.01em;
        color: variable.$gray-font-color;
      }
    }

    a.nav_link p {
      color: variable.$gray-font-color;
    }
    a.nav_link:hover {
      transition: 0.2s ease-in-out;
      background-color: rgba(0, 0, 0, 0.04);
      border-radius: 5px;
    }

    a.nav_link.active {
      box-shadow: none;
      //background-color: variable.$gray-med-color-opacity;
      background-color: unset;
      p {
        color: variable.$black-light-color;
      }
    }
  }

  .centerBurger {
    //padding-left: 15px;
    transition: all 0.5s ease-in-out;
  }

  .content {
    position: relative;
    background-color: transparent;
    height: calc(100% - 80px);
  }

  .dropdown_item {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: variable.$gray-font-color;
    padding: 9px 30px;
  }

  .dropdown_item_dashboards{
    padding: 9px 60px;
  }

  transition: width 0.8s ease-in-out;
}

.nav_link {
  display: flex;
  border-radius: 5px;
  &:hover {
    transition: 0.2s ease-in-out;
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 5px;
  }
}
.nav_link.active {
  box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.05),
    inset 0px 1px 1px variable.$white-color;
  background-color: variable.$gray-light-color;

  p {
    color: variable.$black-light-color;
  }

  svg:first-of-type {
    fill: variable.$black-light-color;

    path {
      fill: variable.$black-light-color;
    }
  }
}

.btn_burger_wrapper {
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  width: 90px;
  border-bottom: 2px solid variable.$gray-light-color;
  border-right: 2px solid variable.$gray-light-color;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: variable.$gray-lighter-color;
  z-index: 4;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);

  /* &::before {
    content: "";
    position: fixed;
    height: 85px;
    width: calc(360px - 90px - 8px);
    background-color: variable.$gray-lighter-color;
    z-index: -1;
    left: 90px;
    top: 0;
    transition: all 1s both cubic-bezier(0.075, 0.82, 0.165, 1);
  } */
}

.open {
  padding-left: 20px !important;
  padding-right: 20px !important;
  animation: fade-right 0.4s both cubic-bezier(0.075, 0.82, 0.165, 1);

  .centerBurger {
    //padding-left: 10px;
    justify-content: flex-start;
  }

  .btn_burger_wrapper {
    &::before {
      content: "";
      position: fixed;
      height: 85px;
      background-color: variable.$gray-lighter-color;
      z-index: -1;
      left: 90px;
      top: 0;
      width: calc(360px - 90px - 8px);
      transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }
}
.closed {
  padding-left: 20px !important;
  padding-right: 20px !important;

  animation: fade-left 0.4s both cubic-bezier(0.075, 0.82, 0.165, 1);

  .btn_burger_wrapper {
    &::before {
      content: "";
      position: fixed;
      height: 85px;
      background-color: variable.$gray-lighter-color;
      z-index: -1;
      left: 90px;
      top: 0;
      width: 0px;
      transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }

  .list_items {
    justify-content: center;
    p {
      display: none;
    }
  }
  button {
    display: flex;
    justify-content: center;

    div:last-of-type {
      display: none;
    }
    div ~ svg {
      display: none;
    }
  }
  .accord_panel {
    display: none;
  }

  .nav_link {
    justify-content: center;
  }
  /* .btn_burger_wrapper {
    &::before {
      position: fixed;
      height: 85px;
      background-color: variable.$gray-lighter-color;
      z-index: -1;
      left: 90px;
      top: 0;
      width: 0;
      transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  } */
}

.iconPos {
  position: absolute;
  left: 13px;
}

@keyframes fadeRight {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

@keyframes fade-left {
  0% {
    /* -webkit-transform: translateX(360px);
    transform: translateX(360px); */
    width: 360px;
    opacity: 1;
  }
  100% {
    /* -webkit-transform: translateX(90px);
    transform: translateX(90px); */
    width: 90px;
    opacity: 1;
  }
}
@keyframes fade-right {
  0% {
    width: 90px;
    opacity: 1;
  }
  100% {
    width: 360px;
    opacity: 1;
  }
}

@include mixin.smallDesk {
  .wrapper {
    .content {
      height: calc(100% - 70px);
    }
  }
  .iconPos {
    width: 20px;
    height: 20px;
    margin-top: 3px;
  }
  .wrapper {
    width: 75px;

    .list_items {
      padding-top: 10px !important;
      padding-bottom: 10px !important;

      p {
        font-size: 14px;
        //margin-bottom: 4px;
      }
    }
    .accord_item {
      h2 {
        div {
          font-size: 14px;
        }
      }

      > div > div {
        padding-top: 0px;
        padding-bottom: 0;
      }
    }
    .dropdown_item {
      font-size: 14px;
      padding: 8px 20px 8px 28px;
    }
  }
  .closed,
  .open {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
  .centerBurger {
    img {
      width: 28px;
      height: 28px;
    }
  }
  .btn_burger_wrapper {
    height: 65px;
    width: 75px;
  }
  .open {
    .btn_burger_wrapper {
      &::before {
        content: none;
      }
    }
  }
  .closed {
    .btn_burger_wrapper {
      &::before {
        content: none;
      }
    }
  }
  .content {
    margin-top: 0px !important;
    padding-top: 70px !important;
  }

  @keyframes fade-left {
    0% {
      width: 300px;
      opacity: 1;
    }
    100% {
      width: 75px;
      opacity: 1;
    }
  }
  @keyframes fade-right {
    0% {
      width: 75px;
      opacity: 1;
    }
    100% {
      width: 300px;
      opacity: 1;
    }
  }
}
