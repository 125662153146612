@use "../../assets/styles/mixin" as mixin;

.summery_box {
  padding: 15px 15px;
  min-height: 140px;
  border-radius: 5px;
  display: flex;
  flex-grow: 1;
  flex-basis: 23%;
  align-items: center;

  .summery_box_circle {
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 13px;
  }

  &.border_summary_box {
    border: 2px solid #efefef !important;
  }
}

@include mixin.smallDesk {
  .summery_box {
    padding: 13px 13px;
    min-height: 120px;

    .summery_box_circle {
      width: 50px;
      height: 50px;
      margin-right: 8px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  .summery_box p:nth-child(2) {
    font-size: 18px;
  }
}
@include mixin.ipad {
  .summery_box {
    padding: 12px 12px;
    min-height: 115px;
    flex-basis: calc(50% - 8px);
    flex-direction: row;
    align-items: center;

    .summery_box_circle {
      width: 45px;
      height: 45px;
      // margin-right: 13px;
      margin-right: 0px;
      margin-bottom: 10px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    div:nth-child(2) {
      flex: 1;
      text-align: center;
    }

    p:nth-child(2) {
      font-size: 24px;
    }
  }
}
@include mixin.tablet {
  .summery_box {
    width: 100%;
    display: flex;
    flex-basis: 100%;
    flex-direction: row;
    align-items: center;

    .summery_box_circle {
      margin-right: 13px;
      margin-bottom: 0px;
    }
    div:nth-child(2) {
      text-align: left;
    }
  }

  .summery_box + .summery_box {
    margin-top: 8px;
  }
}
