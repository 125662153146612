@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin mdDesk {
  @media only screen and (max-width: 1600px) {
    @content;
  }
}
@mixin smallDesk {
  @media only screen and (max-width: 1340px) {
    @content;
  }
}
@mixin ipad {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (max-width: 768px) {
    @content;
  }
}
@mixin mobile {
  @media only screen and (max-width: 650px) {
    @content;
  }
}
