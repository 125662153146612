@use "../assets/styles/variables" as variable;
@use "../assets/styles/mixin" as mixin;

.form_control {
  width: 100%;
  display: flex;
  margin-bottom: 17px;
  flex-wrap: wrap;

  .textAlignCenter {
    text-align: center;
    input {
      text-align: center;
    }
  }

  label {
    width: 100%;
    display: flex;
    align-items: center;

    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.04em;
    //text-transform: uppercase;
    color: variable.$black-light-color;
    margin-bottom: 7px;

    &.labelCenter {
      justify-content: center;
      text-align: center;
    }
  }

  .form_control_input,
  .form_control_select {
    width: 100%;
    position: relative;
    color: variable.$black-light-color;

    .no_input_icon {
      position: absolute;
      top: 0;
      bottom: 1;
      left: 5px;
      color: #1d1f22;
      background: transparent;
      letter-spacing: 0.01em;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 65px;
    }

    .input_left_icon {
      position: absolute;
      top: 0;
      left: 0px;
      background: #f3f3f3;
      border: 1px solid #efefef;
      border-radius: 5px 0px 0px 5px;
      letter-spacing: 0.01em;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      width: 65px;
    }

    input,
    select,
    textarea {
      color: variable.$black-light-color;
      width: 100%;
      min-height: 40px;
      padding: 10px;
      padding: 12px 20px;
      height: 48px;
      background: variable.$gray-lighter-color;
      border: 1px solid variable.$gray-light-color;
      border-radius: 5px;
      outline: none;
      font-size: 15px;
      transition: all 0.5s ease-in-out;

      &:focus,
      &:focus-visible {
        border: 1px solid variable.$gray-dark-color;
        outline: none;
      }
    }

    &.pr5 input {
      padding-right: 15px;
    }
    &.haveSmallIconLeft input {
      padding-left: 52px;
    }
    &.haveSmallerIconLeft input {
      padding-left: 35px;
    }
    textarea {
      min-height: 100px;
      height: auto;
    }

    &.form_control_full {
      width: 100%;
    }

    .error {
      color: variable.$red-light-color;

      font-weight: 400;
      font-size: 10px;
      line-height: 17px;
      letter-spacing: 0.04em;
    }

    &.form_control_input_pl {
      input,
      select,
      textarea {
        padding-left: 37px;
      }
    }

    &.haveLeftIcon {
      input {
        padding-left: 85px;
      }
    }

    &.inputPL {
      input {
        padding-left: 60px;
      }
    }
  }

  .form_control_input.fieldNoBg {
    input {
      color: variable.$black-light-color !important;
      background: transparent;
      border: 1px solid transparent;
    }
  }
  .form_control_input.fs14 {
    input {
      font-size: 14px !important;
    }
  }
  .form_control_input.fs12 {
    input {
      font-size: 12px !important;
    }
  }
  .form_control_input.fs15 {
    input {
      font-size: 15px !important;
    }
  }

  .form_control_select {
    > div > div:not(:last-of-type) {
      border-color: transparent;
      background-color: #efefef;
      border: 1px solid #efefef;
      //border-radius: 5px 5px 0px 0px;
      border-radius: 5px;
    }

    svg {
      fill: #6f767e;
    }

    div[class$="control"] div:first-of-type div:last-of-type {
      margin: 0;
      width: 10px;
      padding: 0;
    }

    > div > div > div:nth-child(1) {
      min-height: 47px;
      background: #fcfcfc;
      outline: none;
      font-size: 15px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      padding-top: 0px;
      padding-bottom: 0px;
      gap: 1px;
      transition: all 0.5s ease-in-out;

      div[class$="Input"] {
        margin: 0;
        width: 10px;
        padding: 0;
      }

      > div {
        > div {
          padding: 1px 4px;
        }
      }
    }

    &.select_with_icon {
      > div:nth-child(1)
        > div
        > div:nth-child(1)
        div:nth-child(1):first-of-type {
        padding-left: 40px !important;
      }
      > div:nth-child(1) > div > div:nth-child(1) div {
        padding-left: 40px !important;
      }
    }
    > div:nth-child(1) > div > div:nth-child(1) div:nth-child(2),
    > div:nth-child(1) > div > div:nth-child(1) div:nth-child(1) {
      margin: 0 !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    > div:nth-child(1)
      > div
      > div:nth-child(1)
      div:nth-child(1)[class$="multiValue"],
    > div:nth-child(1)
      > div
      > div:nth-child(1)
      div:nth-child(3)[class$="multiValue"],
    > div:nth-child(1)
      > div
      > div:nth-child(1)
      div:nth-child(2)[class$="multiValue"] {
      position: relative;
      background-color: transparent;
      div[role="button"] {
        display: none;
      }
    }
    > div:nth-child(1)
      > div
      > div:nth-child(1)
      div:nth-child(3)[class$="multiValue"]::before,
    > div:nth-child(1)
      > div
      > div:nth-child(1)
      div:nth-child(2)[class$="multiValue"]::before {
      content: ",";
      position: absolute;
      left: -2px;
    }
    > div:nth-child(1) > div > div:nth-child(1) div:nth-child(3) {
      margin: 0 !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    > div > div > div:nth-child(2) {
      background: #fcfcfc;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      span {
        display: none;
      }
    }
  }
  .form_control_select.select_with_form {
    > div:nth-child(1) > div > div:nth-child(1) div:nth-child(1) {
      padding-left: 40px !important;
    }
  }

  &.form_control_error {
    .form_control_input,
    .form_control_select {
      input,
      select,
      textarea {
        border: 2px solid variable.$red-light-color;
        outline: 0;
        color: variable.$red-light-color;

        &:focus {
          border: 2px solid variable.$red-dark-color;
        }
      }

      .input_left_icon {
        height: 44px;
        top: 2px;
        left: 2px;
      }
      .input_left_icon_small {
        left: 2px;
        top: 2px;
        height: 42px;
      }
    }

    label {
      color: variable.$red-light-color;
    }

    .form_control_select {
      > div > div {
        border: 1px solid variable.$red-light-color;
        border-radius: 5px;
      }

      > div > div > div:nth-child(1) {
        border-radius: 5px;
        > div:nth-child(2) {
          border-radius: 5px;
        }
      }
    }
  }
}

.form_group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .form_control {
    .form_control_input,
    .form_control_select {
      width: auto;
    }

    label {
      width: auto;
      min-width: 100px;
      width: 100px;
      margin-right: auto;
    }

    &:not(:first-of-type) {
      margin-left: 7px;
    }
  }

  .d_flex.flex_column {
    margin-right: auto;
  }

  &.form_group_five_input {
    margin-bottom: 30px;

    > label {
      width: 100px;
      margin-right: auto;
    }

    .form_control {
      width: 15%;
      margin-bottom: 0px;

      label {
        margin-bottom: 10px;
      }
    }

    .d_flex.flex_column {
      label:first-of-type {
        margin-bottom: 10px;
      }
    }
  }

  &.form_group_four_input {
    margin-bottom: 30px;

    > label {
      width: 100px;
      margin-right: auto;
    }

    .form_control {
      width: 20%;
      margin-bottom: 0px;

      label {
        margin-bottom: 10px;
      }
    }

    .d_flex.flex_column {
      label:first-of-type {
        margin-bottom: 10px;
      }
    }
  }

  &.form_group_three_input {
    margin-bottom: 30px;

    > label {
      width: 100px;
      margin-right: auto;
    }

    .form_control {
      width: 30%;
      margin-bottom: 0px;

      label {
        margin-bottom: 10px;
      }
    }

    .d_flex.flex_column {
      label:first-of-type {
        margin-bottom: 10px;
      }
    }
  }
}

.child {
  position: relative;
  margin-top: 40px;
}

.uppercase {
  text-transform: uppercase;
}

.h_0 {
  height: 0px;
}

.form_control_select_table {
  width: 100%;
  position: relative;
  color: variable.$black-light-color;

  input,
  select {
    color: variable.$black-light-color;
    width: 100%;
    min-height: 40px;
    padding: 10px;
    padding: 12px 20px;
    height: 20px;
    background: variable.$gray-lighter-color;
    border: 1px solid variable.$gray-light-color;
    border-radius: 0;
    outline: none;
    font-size: 15px;
    transition: all 0.5s ease-in-out;

    &:focus,
    &:focus-visible {
      border: 1px solid variable.$gray-dark-color;
      outline: none;
    }
  }

  &.form_control_full {
    width: 100%;
  }

  &.haveLeftIcon {
    input {
      padding-left: 85px;
    }
  }
}
.form_control_select_table {
  > div > div > div:nth-child(2) {
    width: 23px;

    > div {
      position: absolute;
      top: 9px;
      right: 20px;
      background: white;
    }

    img {
      position: absolute;
      top: 0px;
      right: -3px;
      height: 100%;
    }
  }
  > div > div:not(:last-of-type) {
    border-color: transparent;
    background-color: #efefef;
    border: 1px solid #efefef;
    border-radius: 0;
  }

  svg {
    fill: #6f767e;
  }

  > div > div > div:nth-child(1) {
    min-height: 20px;
    background: transparent;
    outline: none;
    font-size: 13px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    gap: 5px;
    transition: all 0.5s ease-in-out;

    flex-wrap: nowrap;
    overflow-x: hidden;
    max-height: 85px;

    > div {
      > div {
        padding: 1px 4px;
      }
    }
  }

  &.select_with_icon {
    > div:nth-child(1) > div > div:nth-child(1) div:nth-child(1):first-of-type {
      padding-left: 40px !important;
    }
    > div:nth-child(1) > div > div:nth-child(1) div {
      padding-left: 40px !important;
    }
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(2),
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(1) {
    margin: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background: transparent !important;

    div[role="button"] {
      background: #f4f4f4 !important;

      svg {
        pointer-events: none;
      }
    }
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(1) {
    max-width: 95px;
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(2) {
    &[class$="multiValue"] {
      position: relative;

      &::before {
        content: ",";
        position: absolute;
        left: -2px;
      }
    }
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(3) {
    margin: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background-color: transparent !important;

    div[role="button"] {
      background: #f4f4f4 !important;
      padding: 2px;
      display: none;
    }

    &[class$="multiValue"] {
      overflow: hidden;
      min-width: 110px;
      position: relative;

      &::before {
        content: ",";
        position: absolute;
        left: -2px;
      }
    }
  }

  > div > div > div:nth-child(2) {
    background: transparent;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    img {
      width: 18px;
    }

    span {
      display: none;
    }
  }
}

.masterRegion .form_control_select_table {
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(3) {
    // max-width: none !important;
    text-overflow: ellipsis;
    overflow: hidden !important;
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(3) {
    &[class$="multiValue"] {
      // overflow: hidden;
      min-width: 50px !important;
      background-color: transparent !important;
      // min-width: 110px;
      // position: relative;
    }
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(1) {
    // max-width: none !important;
    text-overflow: ellipsis;
    overflow: hidden !important;
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(1) {
    &[class$="multiValue"] {
      // overflow: hidden;
      min-width: 50px !important;
      background-color: transparent !important;

      div[role="button"] {
        display: none;
      }
      // min-width: 110px;
      // position: relative;
    }
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(2) {
    // max-width: none !important;
    text-overflow: ellipsis;
    overflow: hidden !important;
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(2) {
    &[class$="multiValue"] {
      // overflow: hidden;
      min-width: 50px !important;
      background-color: transparent !important;
      div[role="button"] {
        display: none;
      }
      // min-width: 110px;
      // position: relative;
    }
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(4) {
    // max-width: none !important;
    text-overflow: ellipsis;
    overflow: hidden !important;
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(4) {
    &[class$="multiValue"] {
      // overflow: hidden;
      min-width: 50px !important;
      background-color: transparent !important;
      div[role="button"] {
        display: none;
      }
      // min-width: 110px;
      // position: relative;
    }
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(5) {
    // max-width: none !important;
    text-overflow: ellipsis;
    overflow: hidden !important;
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(5) {
    &[class$="multiValue"] {
      // overflow: hidden;
      min-width: 50px !important;
      background-color: transparent !important;
      div[role="button"] {
        display: none;
      }
      // min-width: 110px;
      // position: relative;
    }
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(6) {
    // max-width: none !important;
    text-overflow: ellipsis;
    overflow: hidden !important;
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(6) {
    &[class$="multiValue"] {
      // overflow: hidden;
      min-width: 50px !important;
      background-color: transparent !important;
      div[role="button"] {
        display: none;
      }
      // min-width: 110px;
      // position: relative;
    }
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(7) {
    // max-width: none !important;
    text-overflow: ellipsis;
    overflow: hidden !important;
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(7) {
    &[class$="multiValue"] {
      // overflow: hidden;
      min-width: 50px !important;
      background-color: transparent !important;
      div[role="button"] {
        display: none;
      }
      // min-width: 110px;
      // position: relative;
    }
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(8) {
    // max-width: none !important;
    text-overflow: ellipsis;
    overflow: hidden !important;
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(8) {
    &[class$="multiValue"] {
      // overflow: hidden;
      min-width: 50px !important;
      background-color: transparent !important;
      div[role="button"] {
        display: none;
      }
      // min-width: 110px;
      // position: relative;
    }
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(9) {
    // max-width: none !important;
    text-overflow: ellipsis;
    overflow: hidden !important;
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(9) {
    &[class$="multiValue"] {
      // overflow: hidden;
      min-width: 50px !important;
      background-color: transparent !important;
      div[role="button"] {
        display: none;
      }
      // min-width: 110px;
      // position: relative;
    }
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(10) {
    // max-width: none !important;
    text-overflow: ellipsis;
    overflow: hidden !important;
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(10) {
    &[class$="multiValue"] {
      // overflow: hidden;
      min-width: 50px !important;
      background-color: transparent !important;
      div[role="button"] {
        display: none;
      }
      // min-width: 110px;
      // position: relative;
    }
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(11) {
    // max-width: none !important;
    text-overflow: ellipsis;
    overflow: hidden !important;
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(11) {
    &[class$="multiValue"] {
      // overflow: hidden;
      min-width: 50px !important;
      background-color: transparent !important;
      div[role="button"] {
        display: none;
      }
      // min-width: 110px;
      // position: relative;
    }
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(12) {
    // max-width: none !important;
    text-overflow: ellipsis;
    overflow: hidden !important;
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(12) {
    &[class$="multiValue"] {
      // overflow: hidden;
      min-width: 50px !important;
      background-color: transparent !important;
      div[role="button"] {
        display: none;
      }
      // min-width: 110px;
      // position: relative;
    }
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(13) {
    // max-width: none !important;
    text-overflow: ellipsis;
    overflow: hidden !important;
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(13) {
    &[class$="multiValue"] {
      // overflow: hidden;
      min-width: 50px !important;
      background-color: transparent !important;
      div[role="button"] {
        display: none;
      }
      // min-width: 110px;
      // position: relative;
    }
  }
}

.form_control_select_table_focus {
  opacity: 1;
}

.form_control_select_table.select_with_form {
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(1) {
    padding-left: 40px !important;
  }
}
.form_control_select_table {
  input,
  select {
    border: 2px solid variable.$red-light-color;
    outline: 0;
    color: variable.$red-light-color;

    &:focus {
      border: 2px solid variable.$red-dark-color;
    }
  }

  .input_left_icon {
    height: 44px;
    top: 2px;
    left: 2px;
  }
}
.form_control_select_table {
  > div > div {
    //border: 1px solid variable.$red-light-color;
    border-radius: 0;
    background-color: transparent;
    border: none;
  }

  > div > div > div:nth-child(1) {
    border-radius: 0;
    > div:nth-child(2) {
      border-radius: 0;
    }
  }
}
.form_control_select_table {
  width: auto;
}
.form_control_select_table.form_control_select_table_focus {
  div[class$="container"] {
    div[class$="control"] {
      > div {
        pointer-events: none;
      }
    }
  }
  > div > div:not(:last-of-type) {
    border-color: transparent;
    background-color: transparent;
    border: 1px solid #efefef;
    border-radius: 0;
  }

  svg {
    fill: #6f767e;
  }

  > div > div > div:nth-child(1) {
    min-height: 15px;
    background: white;
    outline: none;
    font-size: 13px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-top: 0px;
    padding-bottom: 0px;
    gap: 3px;
    transition: all 0.5s ease-in-out;

    > div {
      > div {
        padding: 1px 3px;
      }
    }
  }

  &.select_with_icon {
    > div:nth-child(1) > div > div:nth-child(1) div:nth-child(1):first-of-type {
      padding-left: 40px !important;
    }
    > div:nth-child(1) > div > div:nth-child(1) div {
      padding-left: 40px !important;
    }
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(2),
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(1) {
    margin: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background: transparent !important;

    div[role="button"] {
      background: #f4f4f4 !important;
      padding: 0px;
      display: none;
    }
  }
  > div:nth-child(1)
    > div
    > div:nth-child(1)
    div:nth-child(2)[class$="multiValue"] {
    overflow: hidden;
    min-width: 110px;
  }
  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(1) {
    overflow: visible;

    &[class$="multiValue"] {
      overflow: hidden;
      min-width: 110px;
    }

    &:not([class$="option"]) {
      pointer-events: none;
    }
  }

  > div:nth-child(1) > div > div:nth-child(1) div:nth-child(3) {
    margin: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  > div > div > div:nth-child(2) {
    background: white;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    > div {
      padding: 1px;
    }

    img,
    svg {
      width: 18px;
    }

    span {
      display: none;
    }
  }
}
.labelInfo {
  font-size: 10px;
  margin-left: 6px;
  align-items: bottom;
}
.form_control_select.form_control_select_readable {
  div[class$="container"] {
    div[class$="control"] > div div:not(:last-of-type) {
      display: none !important;
    }
    div[class$="control"] > div:nth-child(2) {
      display: none !important;
    }
  }
}
.input_left_icon_small {
  position: absolute;
  top: 0;
  left: 0px;
  background: #f3f3f3;
  border: 1px solid #efefef;
  border-radius: 5px 0px 0px 5px;
  letter-spacing: 0.01em;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 50px;
}
.input_left_icon_smaller {
  position: absolute;
  top: 0;
  left: 0px;
  background: #f3f3f3;
  border-radius: 5px 0px 0px 5px;
  letter-spacing: 0.01em;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 33px;
}
@include mixin.smallDesk {
  .input_left_icon_smaller {
    position: absolute;
    top: 0;
    left: 0px;
    background: #f3f3f3;
    border-radius: 5px 0px 0px 5px;
    letter-spacing: 0.01em;
    font-weight: 400;
    font-size: 10px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 41px;
    width: 25px;
  }

  .labelInfo {
    font-size: 7px;
    margin-left: 2px;
    display: flex !important;
    height: 100% !important;
  }
  .form_control_select_table {
    input {
      min-height: 36px;
      height: 36px;
      font-size: 13px;
    }
    > div > div > div:nth-child(1) {
      min-height: 39px;
      font-size: 13px;
    }

    > div:nth-child(1) > div > div:nth-child(1) div:nth-child(1) {
      max-width: 80px;
    }
  }
  .form_control {
    margin-bottom: 14px;

    label {
      line-height: 18px;
      font-size: 11px;
    }

    .form_control_input {
      textarea {
        min-height: 80px;
        font-size: 13px;
      }

      &.haveSmallIconLeft input {
        padding-left: 38px;
      }
      &.haveSmallerIconLeft input {
        padding-left: 28px;
      }
      &.pr5_2 input {
        padding-right: 5px;
      }
      &.pr5 input {
        padding-right: 5px;
      }

      input {
        height: 41px;
        font-size: 13px;
      }
      input[disabled] {
        // color: #6f767e;
        color: #9099a4;
      }
      .input_left_icon_small {
        position: absolute;
        top: 0;
        left: 0px;
        background: #f3f3f3;
        border: 1px solid #efefef;
        border-radius: 5px 0px 0px 5px;
        letter-spacing: 0.01em;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 35px;
      }
      .input_left_icon {
        height: 41px;
      }
      .no_input_icon {
        position: absolute;
        top: 0;
        margin-top: -2px;
        left: 5px;
        color: #1d1f22;
        background: transparent;
        letter-spacing: 0.01em;
        font-weight: 400;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        width: 65px;
      }

      &.inputPL {
        input {
          padding-left: 45px;
        }
      }
    }

    .form_control_select {
      input {
        min-height: 36px;
        height: 36px;
        font-size: 13px;
      }
      > div > div > div:nth-child(1) {
        min-height: 39px;
        font-size: 13px;
      }
    }

    &.form_control_error {
      .form_control_input {
        .input_left_icon {
          height: 37px;
          top: 2px;
          left: 2px;
        }
        .input_left_icon_small {
          left: 2px;
          top: 2px;
          height: 37px;
        }
      }
    }
  }
}
